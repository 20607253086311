body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #e6dce0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* div, span {
  display: grid | inline-grid;
} */

/* .App {
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  grid-template-rows: 80px auto;
  grid-gap: 20px;
  border-style: solid;
  border-color: brown;
} */

.App {
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  /* border-style: solid; */
  border-color: none;
}

.component-temp {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  overflow: auto;
}

.component-temp {
  /* border-style: dotted; */
}

.component-select {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 3;
  /* grid-auto-rows: minmax(min-content, max-content); */
}

.component-select {
  /* border-style: groove; */
}

.component-preview {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 6;
  height: 100%;
  /* grid-auto-rows: minmax(min-content, max-content); */
}

/* .component-preview {
  border-style: double;
} */

.component-processing {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 4;
  overflow: auto;
  height: auto;
  /* grid-auto-rows: minmax(min-content, max-content); */
}

.component-processing {
  /* border-style: dotted; */
}

.component-ml {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 4;
  grid-row-end: 6;
  overflow: auto;
  height: auto;
  /* grid-auto-rows: minmax(min-content, max-content); */
}

.component-ml {
  /* border-style: solid; */
}

.component-results {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 5;
  grid-row-end: 6;
  /* grid-auto-rows: minmax(min-content, max-content); */
}

.component-results {
  /* border-style: ridge; */
}

/* Headers */
h1 {
  text-align: center;
}

/* Image carousel section */

.image-carousel {
  /* display: grid;
  grid-template-columns: [v1] 80% [v2] 20% [v3];
  grid-template-rows: [h1] 70% [h2] 30% [h3]; */
}
/* ^^^Overwritten in Carousel.js */

.image-view {
  /* border-color: azure; */
  grid-column: v1 / v3;
  grid-row: h1 / h2;
  /* background-color: blanchedalmond; */
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-information {
  /* border-style: dotted;
  grid-column: v1 / v2;
  grid-row: h1 / h2; */
}
/* ^^^Overwritten in Carousel.js */
.image-options {
  /* border-style: groove;
  grid-column: v2 / v3;
  grid-row: h1 /h2; */
}
/* ^^^Overwritten in Carousel.js */
.image-display {
  /* background-color: "black"; */
  width: "50%";
  height: "70%";
}

/* .overlay{
  z-index: 9;
  margin: 30px;
  background: rgb(25, 24, 126);
} */
/* Overwritten in Carousel.js */

img {
  width: 100%;
  /* margin-left: auto;
  margin-right: auto;
  display: block; */
  /* object-fit: contain; */
}

.image-frame {
  height: 70%;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Table */
.table {
  /* overflow-x: scroll; */
  /* text-align: center; */
}
/* table:hover tr {
  background:gray;
}
table:hover tr:hover ~tr {
  background:none;
} */

td {
  text-align: center;
}

/* Section */
.section-main {
  display: grid;
  /* grid-template-columns: ; */
  grid-template-rows: auto 1fr auto;
  grid-gap: 20px;
  /* border-style: solid; */
  border-color: none;
  /* overflow: scroll; */
  /* display: flex;
  flex-flow: column; */
  /* position: relative; */
}

/* .section-main:focus {
  border-color: black !important;
}

.div:focus {
  border-color: black !important;
} */

.section-header {
  background-color: #4fadc2;
  cursor: pointer;
  border-radius: 15px 15px;
}

.section-component {
  background-color: #c2dbbf;
  /* flex: 1; */
  height: auto;
  border-radius: 25px 15px;
}

.section-footer {
  background-color: #1c595e;
  border-style: double;
  /* border-color: black; */
  bottom: 0;
  min-height: 20px;
  /* position: relative; */
}

.Footer {
  /* margin-left: auto; */
  float: right;
  /* position: absolute; */
  /* right: 0; */
}

/* 
.button {
  background-color: #4cafaf;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
} */
